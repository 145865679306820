import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/hayden/dev/fluidsolarthermal.com/web/src/components/layout-markdown.tsx";
import SEO from '../../components/seo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="FAQ" mdxType="SEO" />
    <h1 {...{
      "id": "frequently-asked-questions"
    }}>{`Frequently Asked Questions`}</h1>
    <h2 {...{
      "id": "general"
    }}>{`GENERAL`}</h2>
    <h3 {...{
      "id": "what-is-a-modular-home"
    }}><strong parentName="h3">{`What is a modular home?`}</strong></h3>
    <p>{`A modular home is made up of several pre-fabricated, interlocking sections. These segments are constructed in a factory and then assembled on site, allowing significant reductions in construction costs.`}</p>
    <h3 {...{
      "id": "what-makes-fluid-solar-unique-from-other-prefabricated-and-modular-construction-companies"
    }}><strong parentName="h3">{`What makes Fluid Solar unique from other prefabricated and modular construction companies?`}</strong></h3>
    <p>{`Our modular homes and commercial buildings are designed to run sustainably, with off-grid capability provided by our Fluid Solar Thermal energy collection technology. This means that a Fluid Solar modular house or building can substantially reduce both production and energy costs, short and long-term.`}</p>
    <h2 {...{
      "id": "design--materials"
    }}>{`DESIGN & MATERIALS`}</h2>
    <h3 {...{
      "id": "what-are-your-homes-made-out-of"
    }}><strong parentName="h3">{`What are your homes made out of?`}</strong></h3>
    <p>{`Each home is made from heavy duty, pre-assembled Australian steel frames protected by non-combustible foam insulation, all clad with COLORBOND® steel. Options include full-width sliding doors, single or double glazed windows and elevated light entry, as well as a range of luxury add-ons.`}</p>
    <h3 {...{
      "id": "what-size-are-your-modules"
    }}><strong parentName="h3">{`What size are your modules?`}</strong></h3>
    <p>{`Our homes come in a vast array of sizes to meet your build needs. From our one-bedroom, one-bathroom, one living space Bungalow to two-storey dwellings with a variety of flexible living spaces, we will have a solution to fit on your build site. As the components can be transported in flat packs on standard vehicles and trailers, sites with difficult or smaller access points can also make appropriate build locations.`}</p>
    <h3 {...{
      "id": "is-it-easy-to-extend-my-fluid-solar-home-or-building-in-future"
    }}><strong parentName="h3">{`Is it easy to extend my Fluid Solar home or building in future?`}</strong></h3>
    <p>{`Yes! One of the benefits of modular design is that it’s a simple process to add an extra section or multiple sections to your home, enabling easy extensions.`}</p>
    <h3 {...{
      "id": "can-i-customise-my-design"
    }}><strong parentName="h3">{`Can I customise my design?`}</strong></h3>
    <p>{`We have a vast array of homes available to satisfy all design briefs, many of which can be equipped with different internal finishings, three grid-dependency options—on-grid, thin-grid or off-grid—and different external claddings to ensure that your home meets your aesthetic and operational requirements as well as your space needs. Providing individual floorplans or creating custom layouts is not possible at this stage, but we are confident that one of our many existing configurations should be suitable for you. If you have questions or would like advice about which of our designs would be best for you, please contact us and make an enquiry.`}</p>
    <h2 {...{
      "id": "sustainability-environment--tech"
    }}>{`SUSTAINABILITY, ENVIRONMENT & TECH`}</h2>
    <h3 {...{
      "id": "what-is-the-minimum-energy-star-rating-of-your-modules"
    }}><strong parentName="h3">{`What is the minimum energy star rating of your modules?`}</strong></h3>
    <p>{`Our modules carry a minimum rating of 8 stars, and we are constantly working to improve our layouts and technology to create smarter, more energy-efficient home designs. To gain a better understanding of the Nationwide House Energy Rating Scheme, you can view their website.`}</p>
    <h3 {...{
      "id": "do-your-modular-homes-comply-with-cyclone-building-codes-and-bushfire-regulations"
    }}><strong parentName="h3">{`Do your modular homes comply with cyclone building codes and bushfire regulations?`}</strong></h3>
    <p>{`Our homes and buildings can be engineered to resist certain Bushfire Attack Levels and cyclone ratings as required. We will adapt to the needs presented by your build site in order to ensure that your home is resilient and secure.`}</p>
    <p><a parentName="p" {...{
        "href": "/Fluid%20Solar%20Thermal%20-%203600x4800%20Refuge%20Unit%20Footings%20(Cyclonic).pdf"
      }}>{`3600x4800 Refuge Unit Footings (Cyclonic) PDF`}</a></p>
    <h3 {...{
      "id": "are-your-homes-designed-to-run-off-grid"
    }}><strong parentName="h3">{`Are your homes designed to run off-grid?`}</strong></h3>
    <p>{`Absolutely. Not only are our homes designed to function independently of the grid using our solar thermal energy systems, they also have a roof-top rainwater collection system and hot and cold water storage and supply. We can also coordinate a grey-water and black-water storage system too, should you require.`}</p>
    <h3 {...{
      "id": "can-i-buy-solar-thermal-systems-for-my-existing-home-or-business"
    }}><strong parentName="h3">{`Can I buy solar thermal systems for my existing home or business?`}</strong></h3>
    <p>{`Yes, in some instances we can retrofit your existing home or business. Our energy collection technology may be able to substantially reduce the power consumption of your building. Please contact us for advice about whether our systems will be appropriate for your project.`}</p>
    <h2 {...{
      "id": "site-preparation-transportation-and-installation"
    }}>{`SITE PREPARATION, TRANSPORTATION AND INSTALLATION`}</h2>
    <h3 {...{
      "id": "how-much-site-preparation-is-necessary"
    }}><strong parentName="h3">{`How much site preparation is necessary?`}</strong></h3>
    <p>{`This varies from site to site and project to project, and will be thoroughly discussed during the consultative stage. Generally, a foundation system will need to be laid on your site to provide our modular structure with stability.`}</p>
    <h3 {...{
      "id": "will-you-need-a-soil-report"
    }}><strong parentName="h3">{`Will you need a soil report?`}</strong></h3>
    <p>{`Yes, we will need a soil report to ensure the condition of the subsoil is accounted for throughout design and construction.`}</p>
    <h3 {...{
      "id": "how-are-building-and-planning-permits-attained"
    }}><strong parentName="h3">{`How are building and planning permits attained?`}</strong></h3>
    <p>{`Whether you require a planning permit is within the jurisdiction of your local council. We will assist in the process by providing architectural drawings and specifications.`}</p>
    <h3 {...{
      "id": "can-your-homes-be-installed-on-sloping-sites"
    }}><strong parentName="h3">{`Can your homes be installed on sloping sites?`}</strong></h3>
    <p>{`Yes, our screw pile foundation system can be adjusted for height, making it practical for use on sloping sites. However, there may be an additional cost for installation depending on the severity of the slope.`}</p>
    <h3 {...{
      "id": "are-your-homes-and-buildings-available-australia-wide"
    }}><strong parentName="h3">{`Are your homes and buildings available Australia-wide?`}</strong></h3>
    <p>{`Yes. Our building solutions are available throughout Australia, no matter how remote.`}</p>
    <h3 {...{
      "id": "how-are-your-homes-transported"
    }}><strong parentName="h3">{`How are your homes transported?`}</strong></h3>
    <p>{`Our homes and buildings are flat-packed and placed in standard shipping containers for easy transportation by road, rail or ship. They can also be palletised for transportation by standard flat-bed trucks and forklifts.`}</p>
    <h3 {...{
      "id": "how-much-does-transportation-cost"
    }}><strong parentName="h3">{`How much does transportation cost?`}</strong></h3>
    <p>{`The transportation cost varies upon the location of your project. This will be calculated at the beginning of the project process at a cost on par or better than the market rate.`}</p>
    <h3 {...{
      "id": "how-long-does-construction-delivery-and-installation-take"
    }}><strong parentName="h3">{`How long does construction, delivery and installation take?`}</strong></h3>
    <p>{`The whole process from construction to delivery and installation to a prepared site will take from 2 to 4 weeks, providing there aren’t any unusual geographical challenges on your chosen site.`}</p>
    <h3 {...{
      "id": "will-i-need-to-organise-electricity-and-plumbing-services-connections-to-my-new-fluid-solar-home-or-building"
    }}><strong parentName="h3">{`Will I need to organise electricity and plumbing services connections to my new Fluid Solar home or building?`}</strong></h3>
    <p>{`It’s up to you! If you’ve decided to remain connected to the grid and facilities, arrangements will be necessary, but off-grid options are also available. Either way, our homes and buildings come pre-wired and pre-plumbed.`}</p>
    <h3 {...{
      "id": "does-my-fluid-solar-modular-home-come-with-a-warranty"
    }}><strong parentName="h3">{`Does my Fluid Solar modular home come with a warranty?`}</strong></h3>
    <p>{`Yes! Your new home comes with a 6.5yr structural warranty that commences from the day of the handover.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      